<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Irregular Merchant</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form v-on:keyup.enter="searchData">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="business">Business Name</label>
                                                <input type="text" id="business" v-model="search.business" placeholder="Enter Business Name" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="day">Irregular(days) <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="day" v-model="search.day" placeholder="Iregular Day" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group" style="margin-top:28px;">
                                                <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body mr-20">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <div style="width:100%; overflow-x:auto;">
                                            <table class="table table-bordered table-striped table-sm text-center">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Owner </th>
                                                        <th>Business Name</th>
                                                        <th>Last Parcel Date</th>
                                                        <th>Address</th>
                                                        <th>Reg. Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Owner </th>
                                                        <th>Business Name</th>
                                                        <th>Last Parcel Date</th>
                                                        <th>Address</th>
                                                        <th>Reg. Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr v-for="(item,key) in listData" :key="key">
                                                        <td>BHKM{{ item.id }}</td>
                                                        <td>{{ item.name }} <br/>{{ item.mobile }}</td>
                                                        <td>{{ item.business }}</td>
                                                        <td>{{ item.orders ? getDate(item.orders[0]) : 'N/A' }}</td>
                                                        <td>{{ item.address }}</td>
                                                        <td>{{ item.created_at | dateformat }}</td>
                                                        <td>{{ getStatus(item.status) }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
    import config from '@/config'
    export default {
        name:'IrregularMerchant',
        data () {
            return { 
                loader: false,   
                merchants:{
                    data:[]
                },
                search: {
                    business: '',
                    day: 3
                },
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created () {
            this.loadData()
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            commonObj () {
                return this.$store.state.commonObj
            }
        },
        methods:{
            searchData () {
                this.loadData()
            },
            async loadData(){   
                console.log('day = ', this.search.day)
                if (parseInt(this.search.day) <= 0 || this.search.day == '') {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Please set day',
                        color: '#dc3545'
                    })
                } else {    
                    this.loader = true 
                    const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })       
                    const response = await config.getData('/merchant/irregular', params)
                    this.loader = false
                    if (response.status == 200) {
                        this.$store.dispatch('setList', response.data.data)
                        this.paginationData(response.data)
                    } else {
                        this.$store.dispatch('setList', [])
                        
                    }
                }
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            getStatus (status) {
                if (status == 1) {
                    return 'Pending'
                } else if (status == 2) {
                    return 'Approved'
                } else if (status == 3) {
                    return 'Incomplete'
                } else {
                    return 'Reject'
                }
            },
            getDate(order) {
                return order ? order.date : '-'
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>